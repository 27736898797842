import { Pipe, PipeTransform } from "@angular/core";
import * as moment from 'moment';
moment.locale('de');

@Pipe({ name: 'localeDate' })
export class LocaleDatePipe implements PipeTransform {
    transform(value: Date): any {
        if (value === null || undefined) {
            return '';
        } else {
            const date = moment(value).format('LLL');
            return date;
        }
    }
}
