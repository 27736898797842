import { HttpClientModule } from '@angular/common/http';
import { NavigationModule } from './navigation/navigation.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { GermanMatPaginatorIntl } from './german-mat-paginator-intl';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, BrowserAnimationsModule, NavigationModule, CoreModule, AppRoutingModule, SharedModule, HttpClientModule],
  providers: [
    { provide: MatPaginatorIntl, useClass: GermanMatPaginatorIntl }
  ],
  exports: [SharedModule],
  bootstrap: [AppComponent]
})
export class AppModule {}
