import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
moment.locale('de');

@Pipe({ name: 'timeStamp' })
export class TimeStampPipe implements PipeTransform {
  transform(value: number): any {
    if (value === null || undefined || value < 1000) {
      return `00m:00s`;
    } else {
      return this.generatetimeStamp(value);
    }
  }

  generatetimeStamp(miliseconds: number) {
    let seconds = Math.floor(miliseconds / 1000);
    let finalSeconds: any = seconds % 60;
    let finalMinutes: any = (seconds - finalSeconds) / 60;

    if (finalMinutes < 10) {
      finalMinutes = `0${finalMinutes}`;
    }

    if (finalSeconds < 10) {
      finalSeconds = `0${finalSeconds}`;
    }

    return `${finalMinutes}m:${finalSeconds}s`;
  }
}
