import { LocaleDatePipe } from './pipes/localeDate';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './material.module';
import { NormalDatePipe } from './pipes/normalDate';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TimeStampPipe } from './pipes/timeStamp';

@NgModule({
  declarations: [LocaleDatePipe, NormalDatePipe, TimeStampPipe],
  imports: [CommonModule, FlexLayoutModule, MaterialModule, ReactiveFormsModule, FormsModule],
  exports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    LocaleDatePipe,
    NormalDatePipe,
    ReactiveFormsModule,
    FormsModule,
    TimeStampPipe
  ]
})
export class SharedModule { }
