import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: AuthenticationService) { }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {


    // Register or login routes
    if (state.url.includes('register') || state.url.includes('login')) {
      // if logged in should not be accessable
      if (this.authenticationService.IsLoggedIn()) {
        this.router.navigateByUrl('/');
        return false;
      } else {
        return true;
      }
    }


    if (this.authenticationService.IsLoggedIn()) {
      return true;
    } else {
      this.router.navigateByUrl('/authentication/login');
      return false;
    }
  }
}
