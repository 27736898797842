<div class="app-container" fxLayout="column">
    <div>
        <mat-toolbar color="primary" *ngIf="this.authenticationService.IsLoggedIn()">
        <div class="toolbar-container" fxLayout="row">
            <div fxFlex>
                <button type="button" class="distanceFromTop" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()" *ngIf="isHandset$ | async">
                    <mat-icon class="material-red" *ngIf="newUsers.length > 0 || newItems.length > 0" aria-label="Side nav toggle icon">info</mat-icon>
                    <mat-icon *ngIf="newUsers.length === 0 && newItems.length === 0" aria-label="Side nav toggle icon">menu</mat-icon>
                </button>
            </div>
            <div fxFlex="0 1 auto" fxFill>
                <div>
                    <a [routerLink]="['/']">
                        <img class="uni-hildesheim-logo" src="../../../assets/logos/uni_hildesheim_logo_toolbar.svg" />
                    </a>
                    <p id="toolbar-title">Universität Hildesheim Medieninformatik - Inventarverwaltung</p>
                </div>
            </div>
            <div fxFlex class="distanceFromTop">
                <app-toolbar-menu></app-toolbar-menu>
            </div>
        </div>
        </mat-toolbar>
    </div>
    <div fxFlex fxLayout="row">
        <mat-sidenav-container class="sidenav-container">
            <mat-sidenav #drawer class="sidenav" fixedInViewport [mode]="'over'" [fixedTopGap]="52">
                <mat-nav-list>
                    <p class="mat-nav-list-caption">Medieninformatik Inventarverwaltung</p>
                    <mat-divider></mat-divider>
                    <mat-list-item>
                        <a mat-line routerLink="/main-page" (click)="drawer.toggle()"><mat-icon class="menu-icon" matPrefix>home</mat-icon><p class="menu-text">Hauptseite</p></a>
                    </mat-list-item>
                    <mat-list-item>
                        <a mat-line (click)="openUsers()" (click)="drawer.toggle()"><mat-icon class="menu-icon" matPrefix>people</mat-icon><p class="menu-text">Nutzer-Übersicht</p></a>
                    </mat-list-item>
                    <mat-list-item>
                        <a mat-line (click)="openInventory()" (click)="drawer.toggle()"><mat-icon class="menu-icon" matPrefix>grading</mat-icon><p class="menu-text">Inventar-Übersicht</p></a>
                    </mat-list-item>
                    <mat-list-item>
                        <a mat-line (click)="openRents()" (click)="drawer.toggle()"><mat-icon class="menu-icon" matPrefix>assignment</mat-icon><p class="menu-text">Ausleihen-Übersicht</p></a>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                    <p id="new-users-headline" *ngIf="newUsers.length > 0">Neue Nutzer:</p>
                    <mat-list-item class="material-red fat" *ngFor="let newUser of newUsers" (click)="editUser(newUser)" (click)="drawer.toggle()"><mat-icon>announcement</mat-icon>{{ newUser.cardId }}</mat-list-item>
                    <mat-divider *ngIf="newUsers.length > 0"></mat-divider>
                    <p id="new-users-headline" *ngIf="newItems.length > 0" >Neue Artikel:</p>
                    <mat-list-item class="material-red fat" *ngFor="let newItem of newItems" (click)="editItem(newItem)" (click)="drawer.toggle()"><mat-icon>announcement</mat-icon>{{ newItem.tagId }}</mat-list-item>
                    <mat-divider *ngIf="newUsers.length > 0"></mat-divider>
                    <p id="new-users-headline" *ngIf="delayedRents.length > 0" >Verspätete Ausleihen:</p>
                    <mat-list-item class="material-red fat" *ngFor="let delayedRent of delayedRents" (click)="openRentHistory(delayedRent)" (click)="drawer.toggle()"><mat-icon>announcement</mat-icon>{{ delayedRent.item }}</mat-list-item>
                    <mat-divider *ngIf="delayedRents.length > 0"></mat-divider>
                </mat-nav-list>
            </mat-sidenav>
            <mat-sidenav-content fxFlex>
                <router-outlet></router-outlet>
            </mat-sidenav-content>
        </mat-sidenav-container>
    </div>
</div>
