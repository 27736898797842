import { Device } from './../../core/interfaces/IDevice';
import { User } from './../../core/interfaces/IUser';
import { Item } from './../../core/interfaces/IItem';
import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
    providedIn: 'root'
})
export class EventEmitterService {

    public invokeDeleteDeviceFunction = new EventEmitter();
    public invokeDeleteItemFunction = new EventEmitter();
    public invokeDeleteUserFunction = new EventEmitter();
    public invokeEditItemFunction = new EventEmitter();
    public invokeEditUserFunction = new EventEmitter();
    public invokeExtendRentFunction = new EventEmitter();
    public invokeNewDeviceFunction = new EventEmitter();
    public invokeNewItemsFunction = new EventEmitter();
    public invokeNewUsersFunction = new EventEmitter();
    public invokeOpenUserOverview = new EventEmitter();
    public invokeOpenInventoryOverview = new EventEmitter();
    public invokeOpenRentsOverview = new EventEmitter();
    public invokeOpenRentHistory = new EventEmitter();
    public invokeOpenUserHistory = new EventEmitter();
    public invokeOpenRenturnItemDialog = new EventEmitter();
    public subsVar: Subscription;

    constructor() { }

    public onDeleteDeviceClick(device: Device): void {
        this.invokeDeleteDeviceFunction.emit(device);
        console.log(device);
    }

    public onDeleteItemClick(item: Item): void {
        this.invokeDeleteItemFunction.emit(item);
    }

    public onDeleteUserClick(user: User): void {
        this.invokeDeleteUserFunction.emit(user);
    }

    public onEditItemClick(item: Item): void {
        this.invokeEditItemFunction.emit(item);
    }

    public onEditUserClick(user: User): void {
        this.invokeEditUserFunction.emit(user);
    }

    public onExtendRentClick(itemId: number, rentId: number, itemName: string): void {
        this.invokeExtendRentFunction.emit({ itemId, rentId, itemName });
    }

    public onNewDeviceClick(): void {
        this.invokeNewDeviceFunction.emit();
    }

    public onNewItemsLoaded(newItems: Item[]): void {
        this.invokeNewItemsFunction.emit(newItems);
    }

    public onNewUsersLoaded(newUsers: User[]): void {
        this.invokeNewUsersFunction.emit(newUsers);
    }

    public onOpenRentHistoryClick(item: Item): void {
        this.invokeOpenRentHistory.emit(item);
    }

    public onOpenUserHistoryClick(user: User): void {
        this.invokeOpenUserHistory.emit(user);
    }

    public openUsers(): void {
        this.invokeOpenUserOverview.emit();
    }

    public openInventory(): void {
        this.invokeOpenInventoryOverview.emit();
    }

    public openRents(): void {
        this.invokeOpenRentsOverview.emit();
    }

    public onReturnItemClick(id: number, name: string): void {
        console.log(id, name);
        this.invokeOpenRenturnItemDialog.emit({ id, name });
    }
}