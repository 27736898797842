import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/authentication.service';

@Component({
  selector: 'app-toolbar-menu',
  templateUrl: './toolbar-menu.component.html',
  styleUrls: ['./toolbar-menu.component.scss']
})
export class ToolbarMenuComponent implements OnInit {
  public initials = '';
  public firstName = '';
  public lastName = '';
  public username = '';
  constructor(public authenticationService: AuthenticationService) {}

  public ngOnInit(): void {
    this.getInitials();
  }

  private getInitials(): void {
    this.username = sessionStorage.getItem('username');
    if (sessionStorage.getItem('firstName') && sessionStorage.getItem('lastName')) {
      this.firstName = sessionStorage.getItem('firstName');
      this.lastName = sessionStorage.getItem('lastName');
      this.initials = this.firstName.substr(0, 1) + this.lastName.substr(0, 1);
    } else {
      this.initials = this.username.substr(0, 2);
    }
    this.initials.toUpperCase();
  }
}
