import { environment } from './../../../environments/environment';
import { Login } from './../interfaces/ILogin';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import * as moment from 'moment';
import * as jwt_decode from 'jwt-decode';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private decodedToken: any;
    private apiUrl: string;
    private apiPort: number;

    constructor(
        private http: HttpClient,
        private router: Router
    ) {
        this.apiPort = environment.apiPort;
        this.apiUrl = environment.apiUrl;
    }

    public login(loginDto: Login): Observable<any> {
        return this.http.post(this.apiUrl + ':' + this.apiPort + '/web/login', loginDto, { responseType: 'json' });
    }

    public setSession(authResult: any): void {
        this.decodedToken = jwt_decode(authResult.accessToken);
        const expiresAt = moment().add((this.decodedToken.exp - this.decodedToken.iat) * 1000);

        sessionStorage.setItem('jwt_token', authResult.accessToken);
        sessionStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
        sessionStorage.setItem('firstName', this.decodedToken.user.firstName);
        sessionStorage.setItem('lastName', this.decodedToken.user.lastName);
        sessionStorage.setItem('username', this.decodedToken.user.username);
    }

    public IsLoggedIn(): boolean {
        return moment().isBefore(this.getExpiration());
    }

    private getExpiration(): moment.Moment {
        const expiration = sessionStorage.getItem('expires_at');
        const expiresAt = JSON.parse(expiration);
        return moment(expiresAt);
    }

    public logout(): void {
        sessionStorage.clear();
        this.decodedToken = undefined;
        this.router.navigateByUrl('/authentication/login');
    }
}
