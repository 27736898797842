import { environment } from './../../../environments/environment';
import { Item } from './../../core/interfaces/IItem';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class InventoryService {

    private apiUrl: string;
    private apiPort: number;

    constructor(private http: HttpClient) {
        this.apiPort = environment.apiPort;
        this.apiUrl = environment.apiUrl;
    }

    public deleteItem(itemDto: Item): Observable<any> {
        return this.http.post(this.apiUrl + ':' + this.apiPort + '/web/removeItem', itemDto, { responseType: 'json' });
    }

    public getInventory(): Observable<any> {
        return this.http.get(this.apiUrl + ':' + this.apiPort + '/web/getInventory', { responseType: 'json' });
    }

    public getNewItems(): Observable<any> {
        return this.http.get(this.apiUrl + ':' + this.apiPort + '/web/getNewItems', { responseType: 'json' });
    }

    public returnItem(id: number): Observable<any> {
        return this.http.post(this.apiUrl + ':' + this.apiPort + '/web/returnItem', { id }, { responseType: 'json' });
    }

    public setItemData(itemDto: Item): Observable<any> {
        return this.http.post(this.apiUrl + ':' + this.apiPort + '/web/setItemData', itemDto, { responseType: 'json' });
    }
}