import { environment } from './../../../environments/environment';
import { RentsService } from './../../main/main-page-services/rents.service';
import { Rent } from './../../core/interfaces/IRent';
import { Item } from './../../core/interfaces/IItem';
import { User } from './../../core/interfaces/IUser';
import { EventEmitterService } from '../../main/main-page-services/event-emitter.service';
import { InventoryService } from './../../main/main-page-services/inventory.service';
import { UserService } from './../../main/main-page-services/user.service';
import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AuthenticationService } from '../../core/services/authentication.service';
import { Router } from '@angular/router';
import { faRss } from '@fortawesome/free-solid-svg-icons';
import { interval } from 'rxjs';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

    public faRss = faRss;
    public newUsers: User[] = [];
    public newItems: Item[] = [];
    public delayedRents : Rent[] = [];
    public isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
        map(() => true),
        shareReplay()
    );

    constructor(
        public authenticationService: AuthenticationService,
        private breakpointObserver: BreakpointObserver,
        private eventEmitterService: EventEmitterService,
        private inventoryService: InventoryService,
        private rentService: RentsService,
        private router: Router,
        private userService: UserService
    ) {}

    public ngOnInit(): void {
        if (sessionStorage.length > 0) {
            this.loadNewItems();
            this.loadNewUsers();
            this.loadDelayedRents();
        }
        this.setAutoRefresh();
    }

    private setAutoRefresh(): void {
        interval(environment.refreshInterval)
            .pipe()
            .subscribe(() => {
                if (sessionStorage.length > 0) {
                    this.loadNewItems();
                    this.loadNewUsers();
                    this.loadDelayedRents();
                }
        });
    }

    private loadDelayedRents(): void {
        this.rentService
            .getDelayedRents()
            .pipe(
                map((rents: Rent[]) => {
                    this.delayedRents = rents;
                })
            ).subscribe();
    }

    private loadNewItems(): void {
        this.inventoryService
            .getNewItems()
            .pipe(
                map((items: Item[]) => {
                    this.newItems = items;
                    this.eventEmitterService.onNewItemsLoaded(items);
                })
            ).subscribe();
    }

    private loadNewUsers(): void {
        this.userService
            .getNewUsers()
            .pipe(
                map((users: User[]) => {
                    this.newUsers = users;
                    this.eventEmitterService.onNewUsersLoaded(users);
                })
            ).subscribe();
    }

    public navigateToHomeScreen(): void {
        this.router.navigateByUrl('/reader-overview');
    }

    public editItem(item: Item): void {
        this.eventEmitterService.onEditItemClick(item);
    }

    public editUser(user: User): void {
        this.eventEmitterService.onEditUserClick(user);
    }

    public openUsers(): void {
        this.eventEmitterService.openUsers();
    }

    public openInventory(): void {
        this.eventEmitterService.openInventory();
    }

    public openRents(): void {
        this.eventEmitterService.openRents();
    }

    public openRentHistory(rent: Rent): void {
        this.eventEmitterService.onOpenRentHistoryClick({
            id: rent.itemId,
            name: rent.item,
            tagId: null,
            numberExisting: null,
            numberRent: 0,
            rent: true,
            description: null,
            active: true,
            blocked: null,
            addDate: null,
            removeDate: null
        });
    }
}
