<button mat-mini-fab class="header-menu-button" [matMenuTriggerFor]="toolbarMenu">{{ this.initials }}</button>
<mat-menu #toolbarMenu="matMenu">
  <mat-card class="mat-elevation-z0">
    <mat-card-header>
      <div mat-card-avatar><mat-icon class="material-icons md-36">account_circle</mat-icon></div>
      <mat-card-title>{{ this.firstName }} {{ this.lastName }}</mat-card-title>
      <mat-card-subtitle>{{ this.username }}</mat-card-subtitle>
    </mat-card-header>
  </mat-card>
  <mat-divider></mat-divider>

  <button mat-menu-item (click)="this.authenticationService.logout()"><mat-icon>logout</mat-icon><span class="menu-item">Logout</span></button>
</mat-menu>
