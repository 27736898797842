import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RentsService {

    private apiUrl: string;
    private apiPort: number;

    constructor(private http: HttpClient,) {
        this.apiPort = environment.apiPort;
        this.apiUrl = environment.apiUrl;
    }

    public extendRent (rentId: number): Observable<any> {
        return this.http.post(this.apiUrl + ':' + this.apiPort + '/web/extendRent', { id: rentId }, { responseType: 'json' });
    }

    public getActiveRents(): Observable<any> {
        return this.http.get(this.apiUrl + ':' + this.apiPort + '/web/getRents', { responseType: 'json' });
    }

    public getCurrentRent(itemId: number): Observable<any> {
        return this.http.get(this.apiUrl + ':' + this.apiPort + '/web/getRents', { responseType: 'json', params: { itemId: itemId.toString() } });
    }

    public getDelayedRents(): Observable<any> {
        return this.http.get(this.apiUrl + ':' + this.apiPort + '/web/getDelayedRents', { responseType: 'json'});
    }

    public getRentHistory(itemId: number): Observable<any> {
        return this.http.get(this.apiUrl + ':' + this.apiPort + '/web/getRentHistory', { responseType: 'json', params: { id: itemId.toString() } });
    }
}
