import { environment } from './../../../environments/environment';
import { User } from './../../core/interfaces/IUser';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private apiUrl: string;
    private apiPort: number;

    constructor(private http: HttpClient,) {
        this.apiPort = environment.apiPort;
        this.apiUrl = environment.apiUrl;
    }

    public deleteUser(userDto: User): Observable<any> {
        console.log(userDto);
        return this.http.post(this.apiUrl + ':' + this.apiPort + '/web/removeUser', userDto, { responseType: 'json' });
    }

    public getAllUsers(): Observable<any> {
        return this.http.get(this.apiUrl + ':' + this.apiPort + '/web/getUsers', { responseType: 'json' });
    }

    public getNewUsers(): Observable<any> {
        return this.http.get(this.apiUrl + ':' + this.apiPort + '/web/getNewUsers', { responseType: 'json' });
    }

    public setUserData(userDto: User): Observable<any> {
        return this.http.post(this.apiUrl + ':' + this.apiPort + '/web/setUserData', userDto, { responseType: 'json' });
    }

    public getUserHistory(userDto: User): Observable<any> {
        return this.http.get(this.apiUrl + ':' + this.apiPort + '/web/getUserHistory', { responseType: 'json', params: { id: userDto.id.toString() } });
    }
}
