import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: 'authentication',
    loadChildren: () => import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'main-page',
    loadChildren: () => import('./main/main-page.module').then((m) => m.MainPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./main/main-page.module').then((m) => m.MainPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }

  // Wildcard vorest durch '' redirect ersetzt
  // TODO: 404 Page (Wildcard Route **)
  // TODO: Redirect auf Dashboard (Bei Route '')
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
