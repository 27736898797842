import { EventEmitterService } from './../main/main-page-services/event-emitter.service';
import { ToolbarMenuComponent } from './toolbar-menu/toolbar-menu.component';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { SharedModule } from '../shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [ToolbarComponent, ToolbarMenuComponent],
  imports: [SharedModule, RouterModule, FontAwesomeModule],
  providers: [EventEmitterService],
  exports: [ToolbarComponent]
})
export class NavigationModule {}
