import { Pipe, PipeTransform } from "@angular/core";
import * as moment from 'moment';
moment.locale('de');

@Pipe({ name: 'normalDate' })
export class NormalDatePipe implements PipeTransform {
    transform(value: number): any {
        if (value === null || undefined) {
            return '';
        } else {
            return moment(value).format('LLL');
        }
    }
}
